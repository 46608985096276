/** @format */

import React from 'react';
import logo1 from '../images/penskelogo.png';
import logo2 from '../images/mblogo.png';
import logo3 from '../images/boblogo.png';
import logo4 from '../images/ncaalogo.png';
import logo5 from '../images/ryderlogo.png';
import logo6 from '../images/lcglogo.png';
import logo7 from '../images/slblogo.png';
import logo8 from '../images/dynastylogo.png';

const BrandLogos = () => {
    const brands = [
        { name: 'Penske', logo: logo1 },
        { name: 'Mallard Bay', logo: logo2 },
        { name: 'Ryder', logo: logo5 },
        { name: 'NCAA', logo: logo4 },
        { name: 'Bobs Discount Furniture', logo: logo3 },
        { name: 'Learning Care Group', logo: logo6 },
        { name: 'Schlumberger', logo: logo7 },
        { name: 'Dynasty Trusts', logo: logo8 },
    ];

    return (
        <div className="brand-logos">
            {brands.map((brand, index) => (
                <div key={index} className="brand-logo">
                    <img src={brand.logo} alt={brand.name} />
                </div>
            ))}
        </div>
    );
};

export default BrandLogos;
